import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import { componentLabels, urlRouter } from 'utils/constants';
import Logo from 'assets/images/logo/logo-mres.png';
import { useNavigate } from 'react-router-dom';
import { deleteCookie } from 'utils/cookie';

const NavbarContainer = styled.div`
  width: 100%;
  max-width: 1536px;
  margin: 0 2rem 0 2rem;
`;
const DropdownUser = styled.div`
  position: relative;
  & div:nth-child(2) {
    left: 30% !important;
  }
`;

const Navbar = () => {
  const ref = useRef(null);
  const { user, logout } = useAuth0();
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const navigate = useNavigate();

  const handleOpenPartner = () => {
    navigate(`${urlRouter.PARTNERS}`);
  };

  const handleLogout = () => {
    deleteCookie('userId');
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShowDropDown(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isShowDropDown]);

  return (
    <div className="w-full flex justify-center">
      <NavbarContainer className="py-6 flex items-center justify-between">
        <img className="max-h-60px" src={Logo} alt="mres" />
        <DropdownUser id="dropdown-user">
          <div
            id="dropdown-user-text"
            ref={ref}
            data-testid="test-dropdown"
            className="flex items-center cursor-pointer"
            onClick={() => setIsShowDropDown(!isShowDropDown)}
          >
            <span>{user?.name}</span>

            <svg
              className="ml-2 w-4 h-4"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          <div
            id="dropdown-user-item"
            className={`${
              !isShowDropDown && 'hidden'
            } z-10 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 absolute right-0 top-8`}
          >
            <ul
              className="py-1 text-sm text-gray-900 dark:text-gray-200"
              aria-labelledby="dropdownDefault"
            >
              <li data-testid="test-navigate-partners" onClick={handleOpenPartner}>
                <p className="py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  {componentLabels.NAVBAR.PARTNERS}
                </p>
              </li>
              <li data-testid="test-logout" onClick={handleLogout}>
                <p className="py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  {componentLabels.NAVBAR.LOGOUT_LABEL}
                </p>
              </li>
            </ul>
          </div>
        </DropdownUser>
      </NavbarContainer>
    </div>
  );
};

export default Navbar;
