import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './global.css';
import App from 'App.js';

const {
  REACT_APP_SENTRY_ENVIRONMENT: sentryEnvironment,
  REACT_APP_PACKAGE_VERSION,
  REACT_APP_PACKAGE_NAME,
} = process.env;

const sentryReleaseVersion = `${REACT_APP_PACKAGE_NAME}@${REACT_APP_PACKAGE_VERSION}`;

if (sentryReleaseVersion && sentryEnvironment && sentryEnvironment !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: sentryEnvironment,
    release: sentryReleaseVersion,
  });
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);
