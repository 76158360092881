import { withAuthenticationRequired } from '@auth0/auth0-react';
import { MainLayout } from 'components/layouts';
import { TextInput } from 'flowbite-react';
import 'global.css';
import { SearchIcon } from 'components/assets';
import { useState, useEffect, useMemo } from 'react';
import FileListPageContent from 'components/pages/FileList/fileListPageContent';
import debouce from 'lodash.debounce';
import useUser from 'hooks/useUser';
import { setCookie } from '../utils/cookie';
import { useGetCurrentUser } from 'hooks';

const FileList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [enableFetching, setEnableFetching] = useState(false);
  const { userToken } = useUser();

  const handleChange = e => {
    setSearchQuery(e.target.value);
  };
  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 700);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    if (userToken && userToken.length > 0) {
      setEnableFetching(true);
    }
  }, [userToken]);

  const { data } = useGetCurrentUser(userToken, enableFetching);

  useEffect(() => {
    if (data && data.data.id) {
      setCookie('userId', data.data.id);
    }
  }, [data]);
  return (
    <MainLayout>
      <div className="container mx-auto pt-8">
        <div className="sm:w-full md:w-1/2 lg:w-1/3 mb-10">
          <TextInput
            data-testid="test-search-input"
            rightIcon={SearchIcon}
            id="base"
            type="text"
            sizing="md"
            placeholder="Search by File Number or Address"
            onChange={debouncedResults}
            autoFocus
          />
        </div>
        <FileListPageContent searchQuery={searchQuery} />
      </div>
    </MainLayout>
  );
};
export default withAuthenticationRequired(FileList);
