const checkIcon = () => (
  <svg
    className="w-5 h-5"
    fill="none"
    stroke="#2e7c52"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="icon-status"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 13l4 4L19 7"
    />
  </svg>
);
export default checkIcon;
