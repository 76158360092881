import React, { useState, useEffect } from 'react';
import { MainLayout } from 'components/layouts';
import { Sidebar } from 'flowbite-react';
import Section from '../components/pages/FileReview/Section';
import {
  ArrowCircleLeftIcon,
  CheckIcon,
  LockIcon,
  InProgressIcon,
} from 'components/assets';

import useUser from 'hooks/useUser';
import PDFComponent from 'components/pages/FileReview/PDFComponent';
import ModalMPreferred from 'components/pages/FileReview/ModalMPreferred';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import styled from 'styled-components';
import ViewSDKClient from 'components/pages/FileReview/ViewSDKClient';
import { getCookie } from '../utils/cookie';
import { useGetFileReview } from 'hooks';
const FileReview = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

const ReviewPage = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [enableFetching, setEnableFetching] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [lockedByAnotherUser, setLockedByAnotherUser] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const { userToken } = useUser();
  const viewSDKClient = new ViewSDKClient();
  const currentUserId = getCookie('userId');

  const navigate = useNavigate();
  const param = useParams();

  const checkIsLockExpired = lockedUntil => {
    if (lockedUntil !== null && new Date() > new Date(lockedUntil)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (userToken) {
      setEnableFetching(true);
    }
  }, [userToken]);

  const { isLoading, data, isError } = useGetFileReview(
    userToken,
    param.id,
    enableFetching,
  );

  const initSelectedIndex = data?.data?.documents[0]?.id;
  const initSelectedAgent = data?.data?.documents[0];

  useEffect(() => {
    setSelectedIndex(initSelectedIndex);
  }, [data?.data?.id, initSelectedIndex]);

  useEffect(() => {
    setSelectedAgent(initSelectedAgent);
  }, [data?.data?.id, initSelectedAgent]);

  const tempSelectedAgent = data?.data?.documents?.find(
    item => item.id === selectedAgent?.id,
  );

  useEffect(() => {
    if (selectedAgent) {
      setSelectedAgent(tempSelectedAgent);
      if (
        selectedAgent.lockedByUserId !== null &&
        selectedAgent.lockedUntil !== null &&
        !checkIsLockExpired(selectedAgent.lockedUntil)
      ) {
        setIsLocked(true);
        if (selectedAgent.lockedByUserId !== currentUserId) {
          setLockedByAnotherUser(true);
        } else setLockedByAnotherUser(false);
      } else {
        setLockedByAnotherUser(false);
        setIsLocked(false);
      }
    }
  }, [data?.data?.documents, tempSelectedAgent, selectedAgent, currentUserId]);

  useEffect(() => {
    setCurrentPage(1);
    setTotalPage(0);
  }, [selectedAgent?.id, userToken]);

  if (isLoading) {
    return (
      <MainLayout hasNavbar={false}>
        <span data-testid="test-loader" className="w-full flex justify-center">
          Files Loading...
        </span>
      </MainLayout>
    );
  }

  if (isError) {
    return (
      <MainLayout hasNavbar={false}>
        <span data-testid="test-error" className="w-full flex justify-center">
          An Error occured When fetching the data
        </span>
      </MainLayout>
    );
  }

  const handleSelectedFile = item => {
    setSelectedIndex(item.id);
    setSelectedAgent(item);
  };

  const handleGenerateReport = () => {
    setIsOpenModal(true);
  };
  const verifiedDocs = data?.data?.documents?.filter(item => item.verified).length;

  const handleClickBack = () => {
    navigate('/');
  };

  const renderIcon = item => {
    if (item.lockedByUserId !== null && !checkIsLockExpired(item.lockedUntil)) {
      if (item.lockedByUserId !== currentUserId) {
        return <LockIcon />;
      } else if (item.lockedByUserId === currentUserId && item.verified) {
        return <CheckIcon />;
      } else if (item.lockedByUserId === currentUserId && !item.verified) {
        return <InProgressIcon />;
      }
    } else if (item.verified) {
      return <CheckIcon />;
    } else
      return (
        <div className="flex items-center" data-testid="icon-status">
          <span className="rounded-full bg-blue-500 w-1.5 h-1.5 mr-3 " />
        </div>
      );
  };

  return (
    <MainLayout hasNavbar={false}>
      <FileReview>
        <div className="w-2/12 max-w-450px">
          <Sidebar
            style={{ width: '100%' }}
            aria-label="Sidebar with content separator example"
          >
            <Sidebar.Items>
              <div className="flex justify-between pl-5 pr-5">
                <div data-testid="test-back-icon" onClick={handleClickBack}>
                  <ArrowCircleLeftIcon />
                </div>
                <div data-testid="isVerified"  data-verified={!!data?.data?.isVerified}>{`File #${data?.data?.reswareFileNumber}`}</div>
              </div>
              <Sidebar.ItemGroup>
                {data?.data?.documents?.map((item, index) => (
                  <Sidebar.Item
                    key={index}
                    href="#"
                    onClick={() => handleSelectedFile(item)}
                    style={
                      selectedIndex === item.id ? { backgroundColor: '#e9f5ff' } : {}
                    }
                  >
                    <div className="flex justify-between items-center">
                      <div>{item.reswareDocumentTypeId === 1333 ? 'BMA 1' : item.reswareDocumentTypeId === 1334 ? 'BMA 2' : item.reswareDocumentTypeId === 1335 ? 'BMA 3' : 'Unknown Doc Type Id'}</div>
                      {renderIcon(item)}
                    </div>
                  </Sidebar.Item>
                ))}
              </Sidebar.ItemGroup>
              {verifiedDocs >= 2 && (
                <div className="flex justify-center mt-5">
                  <button
                    data-testid="test-generate-report-button"
                    type="button"
                    className="px-3 py-1 text-xs font-medium text-center text-white bg-blue-500 rounded-lg hover:text-white border border-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={handleGenerateReport}
                  >
                    Generate Report
                  </button>
                </div>
              )}
            </Sidebar.Items>
          </Sidebar>
        </div>
        <div className="flex-1 bg-gray-100 p-5 h-full">
          {selectedAgent && (
            <PDFComponent
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
              setTotalPage={setTotalPage}
              documentId={selectedAgent?.id}
              userToken={userToken}
              viewSDKClientComponent={viewSDKClient}
            />
          )}
        </div>
        <div className="w-3/12 max-w-520px px-3 h-full">
          {selectedAgent && (
            <Section
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
              selectedAgent={selectedAgent}
              fileId={param.id}
              lockedByAnotherUser={lockedByAnotherUser}
              isLocked={isLocked}
              setIsLocked={setIsLocked}
            />
          )}
        </div>
        <ModalMPreferred
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          fileId={param.id}
          userToken={userToken}
        />
      </FileReview>
    </MainLayout>
  );
};
export default withAuthenticationRequired(ReviewPage);
