import React, { useState, useEffect } from 'react';
import { Modal, Label, TextInput } from 'flowbite-react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { renderStar } from 'utils/utils';
import DataTable from 'components/DataTable';
import { pageData } from 'utils/constants';
import { useFormik } from 'formik';
import useUser from 'hooks/useUser';
import { Pagination } from 'flowbite-react';
import { formatDate } from 'utils/utils';
import { useGetListPartnerDocuments, useUpdatePartner } from 'hooks';

const Header = styled.div`
  & h3 {
    padding: 8px 0px 24px 8px;
  }
`;

const ModalPartner = ({
  isOpenModal,
  setIsOpenModal,
  partnerData,
  setSelectedPartner,
  setEnableFetchingPartner,
}) => {
  const { userToken } = useUser();
  const [enableFetching, setEnableFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [partnerDocumentEntities, setPartnerDocumentEntities] = useState([]);

  const { isLoading, data, isError } = useGetListPartnerDocuments(
    partnerData.id,
    currentPage,
    userToken,
    enableFetching,
  );

  const { update } = useUpdatePartner();

  const formik = useFormik({
    initialValues: {
      partnerCompanyId: partnerData.partnerCompanyId,
      partnerCompanyName: partnerData.partnerCompanyName,
      scoreAdjustment: partnerData.scoreAdjustment,
    },
    onSubmit: values => {
      update([partnerData.id, userToken, values]);
    },
    enableReinitialize: true,
  });

  const handleCloseModal = () => {
    setIsOpenModal(!isOpenModal);
    setSelectedPartner(null);
    setEnableFetchingPartner(false);
  };

  useEffect(() => {
    if (userToken) {
      setEnableFetching(true);
    }
  }, [userToken]);

  useEffect(() => {
    const partnerDocumentsTemp = data
      ? data.data.entities.map(entity => {
          const totalScore = entity.realizedPartnerScore + partnerData.scoreAdjustment;
          const percentTemp =
            entity.possiblePartnerScore === null
              ? `${0} %`
              : `${((totalScore / entity.possiblePartnerScore) * 100).toFixed(2)} %`;

          return {
            createdDate: formatDate(entity.created),
            bmaScore: entity.realizedPartnerScore,
            totalScore,
            percent: percentTemp,
            isEmpty: true,
          };
        })
      : [];
    setPartnerDocumentEntities(partnerDocumentsTemp);
    const pageCountTemp = data ? Math.ceil(data.data.totalEntities / 10) : 1;
    setPageCount(pageCountTemp);
  }, [data, partnerData]);

  return (
    <Modal
      show={isOpenModal}
      size="4xl"
      popup={true}
      onClose={() => handleCloseModal()}
      id="modal-partner"
    >
      <Header>
        <Modal.Header>{partnerData.partnerCompanyName}</Modal.Header>
      </Header>
      <Modal.Body>
        <div className="flex pb-8">
          <div className="w-1/3">
            <div className="mb-2 block">
              <Label
                className="font-medium text-sm not-italic"
                htmlFor="BMA2PartnerScore"
                value="Base Score"
              />
            </div>
            <div className="flex">
              <div className="w-1/2">
                <TextInput
                  name="scoreAdjustment"
                  id="scoreAdjustment"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.scoreAdjustment}
                />
              </div>
              <div className="w-1/2 pl-2.5 flex items-center">
                <button
                  type="submit"
                  className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-500 rounded-lg hover:text-white border border-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={formik.handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="w-2/3">
            {' '}
            <div className="mb-2 block">
              <Label
                className="font-medium text-sm not-italic"
                htmlFor="BMA2PartnerScore"
                value="Rating"
              />
            </div>
            {renderStar(partnerData?.rating)}
          </div>
        </div>
        {isLoading ? (
          <span className="w-full flex justify-center">Loading...</span>
        ) : isError ? (
          <span className="w-full flex justify-center">
            An Error occured When fetching the data
          </span>
        ) : (
          <>
            <DataTable
              columns={pageData.PARTNER_DOCUMENTS.TABLE_COLUMNS_DATA}
              data={partnerDocumentEntities}
            />
            {pageCount > 1 && (
              <div className="flex items-center justify-center mt-8">
                <Pagination
                  currentPage={currentPage}
                  totalPages={pageCount}
                  onPageChange={page => {
                    setCurrentPage(page);
                  }}
                />
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
ModalPartner.propTypes = {
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
};
export default ModalPartner;
